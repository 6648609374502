<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="Datasource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
        <br>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'NotSentDeliveryDashboardGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dashboardData'],
    computed: {
        Datasource(){
            var data = { data : this.$props.dashboardData };
            
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: function (e) {
                        e.success(data);
                    },
                },
                schema: {
                    data: function (response) {
                        if(response.data == null){
                            return [];
                        }else{
                            return response.data;
                        }
                    },
                    total: function (response) {
                        if(response.data == null){
                            return 0;
                        }else{
                            return response.data.length;
                        }
                    }
                },
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                // { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "do_quantity", title: "Jumlah Delivery", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "invoice_quantity", title: "Jumlah Invoice", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "purchase_order_number", title: "Nomor PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "delivery_order_numbers", title: "Nomor DO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    async mounted() {
        // var vueComponent = this;
        // var grid = this.$refs.grid.kendoWidget();
        // var GridElement = grid.element;

        // GridElement.on("click", "#ViewButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));
        //     var typeText = dataItem.invoice_type == 1 ? 'Invoice' : 'Deposit';

        //     vueComponent.$router.push({ name: 'Invoice Form', params: { formtype:'Edit', id:dataItem.invoice_id, view:true, type:typeText } });
        // })
    },
    methods: {
        // columnButton(){
        //     return `<button type="button" class="btn btn-info btn-sm rounded" id="ViewButton">
        //                 <i class="fa fa-eye"></i> </span>
        //             </button>`;
        // },
    }
}
</script>