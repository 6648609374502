<template>
  <div>
    <div class="row">
      <CSelect id="DashboardType" class="font-weight-bold w-100" :options="DashboardTypeData" :value.sync="DashboardType" @change="onChangeDashboardType()"/>
    </div>
    <CRow v-for="(data, i) in DashboardDatas" :key="i">
      <div v-if="data['Type'] == 'Card'" style="width:100%">
        <card-dashboard :key="gridReload" :dashboardData="data['Data']"/>
      </div>
      <div v-if="data['Type'] == 'Grid'" style="width:100%">
        <div v-if="data['Name'] == 'Invoice'" style="width:100%">
          <invoice-dashboard-grid :key="gridReload" :dashboardData="data['Data']"/>
        </div>
        <div v-if="data['Name'] == 'Production'" style="width:100%">
          <production-dashboard-grid :key="gridReload" :dashboardData="data['Data']"/>
        </div>
        <div v-if="data['Name'] == 'Not Sent Delivery'" style="width:100%">
          <not-sent-delivery-dashboard-grid :key="gridReload" :dashboardData="data['Data']"/>
        </div>
      </div>
    </CRow>
  </div>
</template>

<script>
import dashboardServices from './Script/DashboardServices';
import invoiceDashboardGrid from './Grid/InvoiceDashboardGrid.vue';
import productionDashboardGrid from './Grid/ProductionDashboardGrid.vue';
import notSentDeliveryDashboardGrid from './Grid/NotSentDeliveryDashboardGrid.vue';
import cardDashboard from './Component/DashboardCard.vue';

import { dashboard } from '../../../infrastructure/constant/variable';

export default {
  name: 'Dashboard',
  components: {
    'invoice-dashboard-grid': invoiceDashboardGrid,
    'production-dashboard-grid': productionDashboardGrid,
    'not-sent-delivery-dashboard-grid': notSentDeliveryDashboardGrid,
    'card-dashboard': cardDashboard,
  },
  async mounted () {
    this.DashboardTypeData = await dashboardServices.getDashboardType();
    this.DashboardType = this.DashboardTypeData[0].value;

    this.onChangeDashboardType();
  },
  data () {
    return {
      DashboardTypeData : [],
      DashboardType : null,
      gridReload : 0,
      DashboardDatas : []
    }
  },
  methods: {
    async onChangeDashboardType(){
      var data = await dashboardServices.getDataDashboard(this.DashboardType); 
      if(data.length > 0){
        this.DashboardDatas = data

        for (let i = 0; i < this.DashboardDatas.length; i++) {
          if(this.DashboardDatas[i].Type == "Card"){
            var data = this.DashboardDatas[i].Data;
            for (let i = 0; i < data.length; i++) {
                var itemElement = data[i];
                itemElement['PrimaryColor'] = dashboard.card_color[i].primary
                itemElement['SecondaryColor'] = dashboard.card_color[i].secondary
            }
          }
        }

        this.gridReload++;
      }
    }
  }
}
</script>
