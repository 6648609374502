<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="Datasource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
        <br>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionDashboardGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dashboardData'],
    computed: {
        Datasource(){
            var data = { data : this.$props.dashboardData };
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: function (e) {
                        e.success(data);
                    },
                },
                schema: {
                    data: function (response) {
                        if(response.data == null){
                            return [];
                        }else{
                            return response.data;
                        }
                    },
                    total: function (response) {
                        if(response.data == null){
                            return 0;
                        }else{
                            return response.data.length;
                        }
                    }
                },
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 150, title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "production_type", width: 150, title: "Tipe Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= production_type == 1 ? "Internal" : "External" #' },
                { field: "production_code", width: 150, title: "Kode Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_id", width: 150,title: "Mesin", headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "production_result_quantity", width: 150, title: "Quantity", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
                { field: "storage_id", width: 150, title: "Gudang", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "waste_percentage", width: 150, title: "Persentasi Waste", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "producer_id", width: 150, title: "Producer", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "created_at", width: 150, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$router.push({ name: 'Production Card Form', params: { formtype:'Edit', id:dataItem.production_id, view:true, type:dataItem.production_type, status:dataItem.status, bookingitemid:' ' } });
        })
    },
    methods: {
        columnButton(){
            return `<button type="button" class="btn btn-info btn-sm rounded" id="ViewButton">
                        <i class="fa fa-eye"></i> </span>
                    </button>`;
        },
    }
}
</script>