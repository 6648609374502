import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class DashboardService {
    async getDashboardType(){
        const variables = {
            id : parseInt(window.$cookies.get('RoleID'))
        }
        var query = gql`query($id:Int){
                GetRole(RID:$id){
                    role_dashboard{
                        dashboard_id
                        dashboard_name
                    }
                }
            }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var data = result.data.GetRole[0].role_dashboard;
        var dashboardTypeData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].dashboard_id, 
                            label:data[i].dashboard_name}
                dashboardTypeData.push(str);
            }
        }
       
        return dashboardTypeData;
    }

    getDashboardDataQueryGql(){
        var query = gql`
            query($id: Int!){
                GetDashboardData(DashboardID:$id)
            }`;
        return query;
    }

    async getDataDashboard(id){
        var variables = { id : id };
        var response = await globalfunc.defaultApolloQueryDefault(this.getDashboardDataQueryGql(), variables);
        var data = response.data.GetDashboardData;

        return data;
    }
}

export default new DashboardService();